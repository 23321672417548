import { REACT_APP_API_URL } from "../index";
import {
  setAllProductsAction,
  setAvailableSizesAction,
  setCartProductsAction,
  setFavoriteProductsAction,
  setNoveltyProductsAction,
  setPhotosForMainPopular,
  setPhotosForMainSlider,
  setProductAction,
  setSaleProductsAction,
} from "../store/catalogReducer";

export const fetchAllProducts = (query = "") => {
  return (dispatch) => {
    fetch(`${REACT_APP_API_URL}/api/v1/product?${query}`)
      //fetch(`${REACT_APP_API_URL}/api/v1/product?${query}`)
      .then((response) => response.json())
      .then((json) => {
        // json.sort((a, b) => a.id - b.id);
        const products = [];
        json.results.map((item) => {
          products.push(item.products);
        });
        if (products) {
          dispatch(setAllProductsAction(products));
        } else {
          dispatch(setAllProductsAction(["empty"]));
        }

        // if (products.length > 0) {
        //   const minPrice = Number(
        //     products.reduce((min, current) =>
        //       Number(min.price) < Number(current.price) ? min : current
        //     ).price
        //   );
        //   dispatch(setPriceFromAction(minPrice));
        //
        //   const maxPrice = Number(
        //     products.reduce((min, current) =>
        //       min.price > current.price ? min : current
        //     ).price
        //   );
        //   dispatch(setPriceToAction(maxPrice));
        // } else {
        //   dispatch(setPriceFromAction(0));
        //   dispatch(setPriceToAction(500));
        // }
      })
      .catch(() => console.log("Error: fetchAllProducts"));
  };
};
export const fetchSearchAllProducts = (query) => {
  return (dispatch) => {
    fetch(`${REACT_APP_API_URL}api/v1/product/search/?keywords=${query}`)
      //fetch(`${REACT_APP_API_URL}/api/v1/product?${query}`)
      .then((response) => response.json())
      .then((json) => {
        if (json) {
          dispatch(setAllProductsAction(json));
        } else {
          dispatch(setAllProductsAction(["empty"]));
        }
      })
      .catch(() => dispatch(setAllProductsAction(["empty"])));
  };
};

export const fetchAllSaleProducts = (query = "") => {
  return (dispatch) => {
    fetch(`${REACT_APP_API_URL}/api/v1/discounted-products${query}`)
      .then((response) => response.json())
      .then((json) => {
        dispatch(setSaleProductsAction(json.results));
      })
      .catch(() => dispatch(setSaleProductsAction([])));
  };
};
export const fetchAllNoveltyProducts = (query = "") => {
  return (dispatch) => {
    fetch(`${REACT_APP_API_URL}/api/v1/product/new-collection${query}`)
      .then((response) => response.json())
      .then((json) => {
        const products = json.map((item) => item.products);
        dispatch(setNoveltyProductsAction(products));
      })
      .catch(() => dispatch(setNoveltyProductsAction([])));
  };
};
export const fetchFavoriteProducts = (ids) => {
  const queryString = ids.join(",");
  return (dispatch) => {
    fetch(
      `${REACT_APP_API_URL}/api/v1/product/get-products/?products=${queryString}`
    )
      .then((response) => response.json())
      .then((json) => {
        dispatch(setFavoriteProductsAction(json));
      })
      .catch(() => dispatch(setFavoriteProductsAction([])));
  };
};
export const fetchCartProducts = (ids) => {
  const queryString = ids.join(",");
  return (dispatch) => {
    fetch(
      `${REACT_APP_API_URL}/api/v1/product/get-products/?products=${queryString}`
    )
      .then((response) => response.json())
      .then((json) => {
        dispatch(setCartProductsAction(json));
      })
      .catch(() => dispatch(setCartProductsAction([])));
  };
};

export const fetchProductById = (id) => {
  return (dispatch) => {
    fetch(`${REACT_APP_API_URL}/api/v1/product/get-products/?products=${id}`)
      .then((response) => response.json())
      .then((json) => {
        dispatch(setProductAction(json[0]));
        dispatch(setAvailableSizesAction(json[0].sizes));
      })
      .catch(() => console.log("Error: fetchProductById"));
  };
};
export const fetchPhotosForMain = () => {
  return (dispatch) => {
    fetch(`${REACT_APP_API_URL}api/v1/banners/`)
      .then((response) => response.json())
      .then((json) => {
        const slider = [];
        const popular = [];
        if (!!json.count && json.count > 0) {
          json.results.map((photo) => {
            if (photo.main) {
              slider.push(photo.image_path);
            } else {
              popular.push({
                image_path: photo.image_path,
                season_category: photo.season_category,
              });
            }
          });
        }
        dispatch(setPhotosForMainSlider(slider));
        dispatch(setPhotosForMainPopular(popular));
      })
      .catch(() => console.log("Error: fetchPhotosForMain"));
  };
};
