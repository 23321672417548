import React from "react";
import { useSelector } from "react-redux";
import { CATALOG_ROUTE } from "../../../routes";

const ColorFragment = () => {
  const product = useSelector((state) => state.products.product);
  console.log(product.colors);
  return (
    <div>
      <div className={"fragment_title"}>Цвет</div>
      <div className={"product_info_description_color"}>
        <div
          className="product_info_description_color_border"
          style={{ backgroundColor: product.color.hex_number }}
        ></div>
      </div>
    </div>
  );
};

export default ColorFragment;
