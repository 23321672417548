import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchField from "./searchField";

import cart from "./icons/bag.svg";
import heart from "./icons/heart.svg";

import "./header.scss";
import { PhoneNumber1 } from "../../../app";
import { useSelector } from "react-redux";
import MobileMenu2 from "./mobileMenu2";
import UpperSearchField from "./upperSearchField";
const DEFAULT_CLASSNAME = "header";

const HeaderTop = () => {
  const navigate = useNavigate();
  const idsCartProducts = useSelector(
    (state) => state.products.idsCartProducts
  );
  const idsFavoriteProducts = useSelector(
    (state) => state.products.idsFavoriteProducts
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div
      className={`${DEFAULT_CLASSNAME}`}
      style={{ boxShadow: "0 0 10px rgba(0,0,0,.2)", flexDirection: "column" }}
    >
      <div className={`${DEFAULT_CLASSNAME}_wrapper container`}>
        <div className={`${DEFAULT_CLASSNAME}_mobile-search`}>
          <UpperSearchField />
        </div>

        <div className={`${DEFAULT_CLASSNAME}_wrapper_navigation`}>
          <span
            style={{ width: "150px" }}
            className={`${DEFAULT_CLASSNAME}_wrapper_contacts_item`}
          >
            <PhoneNumber1 />
          </span>
        </div>
        <div>
          <a
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="button b-mobile-button menu _js-b-toggle-mobile-menu"
          >
            Меню
            <div className="customize-css-icon">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </a>
        </div>
        {isMenuOpen && <MobileMenu2 close={() => setIsMenuOpen(false)} />}

        <div
          className={`${DEFAULT_CLASSNAME}_wrapper_logo`}
          onClick={() => navigate("/")}
        >
          <span>{"del-corso"}</span>
        </div>
        <div className={`${DEFAULT_CLASSNAME}_wrapper_control-panel`}>
          {/*<SearchField2 />*/}
          <div className={`${DEFAULT_CLASSNAME}_wrapper_search`}>
            <SearchField />
          </div>
          <div
            className={`${DEFAULT_CLASSNAME}_wrapper_control-panel_cart`}
            style={{ display: "flex", flexDirection: "row" }}
            onClick={() => navigate("/cart")}
          >
            <img
              src={cart}
              alt={"cart-icon"}
              style={{
                height: "18px",
                width: "18px",
                display: "flex",
                alignItems: "center",
                maxWidth: "none",
              }}
            />
            <div style={{ fontSize: "14px" }}>{idsCartProducts.length}</div>
          </div>
          <div
            className={`${DEFAULT_CLASSNAME}_wrapper_control-panel_profile`}
            style={{ display: "flex", flexDirection: "row" }}
            onClick={() => {
              navigate("/favorites");
            }}
          >
            <img
              src={heart}
              alt={"cart-icon"}
              style={{
                height: "18px",
                width: "18px",
                display: "flex",
                alignItems: "center",
                maxWidth: "none",
              }}
            />
            <div style={{ fontSize: "14px" }}>{idsFavoriteProducts.length}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
