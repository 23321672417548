import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { fetchProductById } from "../asyncActions/catalog";
import SizeFragment from "../components/product/descriptionItems/sizeFragment";
import LoadingComponent from "../components/common/loadingComponent";
import PriceFragment from "../components/product/descriptionItems/priceFragment";
import ColorFragment from "../components/product/descriptionItems/colorFragment";
import CharacteristicFragment from "../components/product/descriptionItems/characteristicFragment";

import ImageItem from "../components/product/imageItem";
import {
  addIdCartProductAction,
  setSelectedSizeAction,
} from "../store/catalogReducer";

import "../components/product/product.scss";
import Title from "../components/common/title";

const Product = () => {
  let product = useSelector((state) => state.products.product);
  const selectedSize = useSelector((state) => state.products.selectedSize);
  const idsCartProducts = useSelector(
    (state) => state.products.idsCartProducts
  );

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchProductById(id));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (idsCartProducts.length > 0) {
      const aa = [];
      idsCartProducts.map((product) => {
        aa.push(`${product.id}-${product.size}`);
      });
      const myString = aa.join(".");
      Cookies.set("cartProducts", myString);
    }
  }, [idsCartProducts]);

  function setErrorField(errorMessage, color) {
    document.getElementById("error").innerHTML = errorMessage;
    document.getElementById("error").style.color = color;
  }

  const handleToggleCart = (size) => {
    if (JSON.stringify(size) !== "{}" && size !== null) {
      if (!idsCartProducts.some((item) => item.id === Number(id))) {
        dispatch(addIdCartProductAction({ id: Number(id), size }));
        dispatch(setSelectedSizeAction({}));
        setErrorField("Товар добавлен в корзину", "#188038");
      } else {
        dispatch(setSelectedSizeAction({}));
        setErrorField("Эта модель уже в корзине", "#EE5151");
      }
    } else {
      setErrorField("Выберите размер", "#EE5151");
    }
  };
  const TitleProduct = () => {
    return (
      <div className={"product_info_title"}>
        <div className={"product_info_title_name"}>{`${product.name}`}</div>
        <div
          className={"product_info_title_vendor_code"}
        >{`Артикул: ${product.vendor_code}`}</div>
      </div>
    );
  };

  return (
    <div className={"product container"}>
      <Title str={`${product.name} купить в Минске, цена`} />
      {product.id === Number(id) ? (
        <div>
          <div className={"product_info"}>
            {!isWideScreen && <TitleProduct />}
            <div className={"product_info_images"}>
              <ImageItem />
            </div>
            <div className={"product_info_description"}>
              {isWideScreen && <TitleProduct />}
              <div>
                <PriceFragment />
              </div>
              <div>
                <ColorFragment />
              </div>
              <div>
                <SizeFragment />
              </div>
              <div className={"product_add_button"}>
                <button
                  onClick={() => {
                    handleToggleCart(selectedSize);
                  }}
                >
                  {"Добавить в корзину"}
                </button>
                <span
                  id="error"
                  style={{
                    marginLeft: "16px",
                    textDecoration: "underline",
                  }}
                />
              </div>
              <div>
                <CharacteristicFragment />
              </div>
            </div>
          </div>
          <div className="hr">
            <hr />
          </div>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

export default Product;
