import React from "react";

import ContactInfoUnit from "./contactInfoUnit";

import "./footer.scss";
const DEFAULT_CLASSNAME = "footer";

export const Footer = () => {
  return (
    <div className={DEFAULT_CLASSNAME}>
      <div className={`container`} style={{ marginTop: "0px" }}>
        <div className={`${DEFAULT_CLASSNAME}_content`}>
          <div className={`${DEFAULT_CLASSNAME}_navigation`}>
            <ContactInfoUnit />
          </div>
        </div>
        <hr />
        <div
          className={`${DEFAULT_CLASSNAME}_content`}
          style={{ marginTop: "10px" }}
        >
          <div className={`${DEFAULT_CLASSNAME}_navigation`}>
            <div className={`${DEFAULT_CLASSNAME}_navigation_item`}>
              <div
                className={`${DEFAULT_CLASSNAME}_navigation_sub-item_logo`}
                style={{ fontSize: 14, width: 150 }}
              >
                {"Del-corso © 2024"}
              </div>
            </div>
            <div className={`${DEFAULT_CLASSNAME}_navigation_item`}>
              <div
                className={`${DEFAULT_CLASSNAME}_navigation_sub-item`}
                style={{ fontSize: 12 }}
              ></div>
            </div>
            <div className={`${DEFAULT_CLASSNAME}_navigation_item`}>
              <div
                className={`${DEFAULT_CLASSNAME}_navigation_sub-item`}
                style={{ fontSize: 12 }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
