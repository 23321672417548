import React, { useState } from "react";
import { CATALOG_ROUTE } from "../../routes";
import { useNavigate } from "react-router-dom";
import NewFlag from "./newFlag";
import FavoriteStar from "./favoriteStar";
import SaleFlag from "./saleFlag";
import { useDispatch } from "react-redux";
import { fetchProductById } from "../../asyncActions/catalog";

import noPhoto from "./nophoto.png";
import { REACT_APP_API_URL } from "../../index";

const CatalogItem = ({ product, isCatalog, isHome = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isHovered, setIsHovered] = useState(false);
  const [isImageHovered, setIsImageHovered] = useState(false);

  const isDiscountProduct = !!product && !!product.discount && Object.keys(product.discount).length !== 0;
  const isNewProduct = !!product && product.new_collection;

  const images = [];
  !!product && !!product.image_paths && product.image_paths.map((image) => {
    images.push(`${REACT_APP_API_URL}product_images/${image}`);
  });

  if (images.length === 0) {
    images.push(noPhoto);
    images.push(noPhoto);
  } else {
    if (images.length === 1) {
      images.push(images[0]);
    }
  }

  return (
    <div
      className={`col-lg-${
        isCatalog ? 4 : 3
      } col-md-4 col-sm-6 col-6 col-product-item padin`}
      style={{ paddingBottom: isHovered ? "6px" : "40px" }}
    >
      {product && <div
        style={{ cursor: "pointer" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => {
          navigate(
            `${CATALOG_ROUTE}/${product.type_category.name}/item/${product.id}`
          );
          dispatch(fetchProductById(product.id));
        }}
      >
        <div
          onMouseEnter={() => setIsImageHovered(true)}
          onMouseLeave={() => setIsImageHovered(false)}
          style={{
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            aspectRatio: 263.33 / 329.16,
            // height: "315.63px",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            src={isImageHovered ? images[1] : images[0]}
            alt="Your Image"
          />
          <FavoriteStar productId={product.id} />
          {isNewProduct ? (
            <NewFlag />
          ) : isDiscountProduct ? (
            <SaleFlag />
          ) : (
            <></>
          )}
        </div>
        <div className={"catalog_item_button"}>
          {isHovered && <button>{"Посмотреть"}</button>}
        </div>

        <div style={{ padding: "0 0 10px" }}>
          <div style={{ fontSize: "16px", padding: "8px 0 0" }}>
            <span
              style={{
                textDecoration: isDiscountProduct ? "line-through" : "none",
                color: isDiscountProduct ? "#909090" : "#000000",
                padding: "0 5px 0 0",
              }}
            >
              {product.price} руб.
            </span>
            {isDiscountProduct && (
              <span style={{ color: "#ee5151", padding: "0 5px" }}>
                {`  ${product.discount.discount_price}`} руб.
              </span>
            )}
          </div>
          <div style={{ fontSize: "16px", padding: "8px 0 0" }}>
            {product.name}
          </div>
          {!isHome && (
            <div style={{ fontSize: "14px", color: "#909090" }}>
              {product.vendor_code}
            </div>
          )}
        </div>
      </div>}
    </div>
  );
};

export default CatalogItem;
