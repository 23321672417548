import React from "react";
import { NavLink } from "react-router-dom";

import MobileMenu from "./mobileMenu";
import {
  CATALOG_ROUTE,
  CONTACT_ROUTE,
  NOVELTY_ROUTE,
  SELLOUT_ROUTE,
} from "../../../routes";

import "./header.scss";
import "./../../../index.scss";
import { useSelector } from "react-redux";

export const NAV_ITEMS = [
  { title: "Каталог", link: CATALOG_ROUTE },
  { title: "Новинки", link: NOVELTY_ROUTE },
  { title: "Распродажа", link: SELLOUT_ROUTE },
  { title: "Контакты", link: CONTACT_ROUTE },
];

const HeaderBottom = () => {
  const types = useSelector((state) => state.searchParameters.types);
  return (
    <div className={`header_bottom`}>
      <div className={`header_bottom_container`}>
        <MobileMenu />
        <nav className={`header_bottom_container_menu`}>
          {NAV_ITEMS.map((item, id) => (
            <li className={`header_bottom_container_menu_item`}>
              <NavLink
                to={item.link}
                key={id.toString()}
                activeClassName="selected"
                className={"header_bottom_container_menu_item_link"}
              >
                <div
                  className={"header_bottom_container_menu_item_link_text"}
                ></div>
                {item.title}
              </NavLink>
              {item.title === "Каталог" && (
                <div className="dropdown">
                  <ul>
                    {types.map((type) => (
                      <li key={type.name} style={{ textAlign: "left" }}>
                        <a href={`/catalog/${type.name}`}>{type.name}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default HeaderBottom;
